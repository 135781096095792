import React, {
  useEffect,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
  ReactNode,
} from 'react';
import { Modal, Button, Progress } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  MinusOutlined,
  FullscreenOutlined,
  DragOutlined,
  UploadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import DocumentUploadList from './DocumentUploadList';

interface UploadImageModalProps {
  isVisible: boolean;
  onUpload: (fileList: FileList) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  uploading: boolean;
  progress: number;
  onCancel: () => void;
  isMinimized: boolean;
  onToggleMinimize: () => void;
  onNavigateToEdit?: () => void;
  isProcessingComplete: boolean;
  maskClosable?: boolean;
}

interface Position {
  x: number;
  y: number;
}

interface FileItem extends UploadFile {
  originFileObj: File;
  type: string;
}

interface FileList extends Array<FileItem> {
  length: number;
}

interface UploadChangeParam {
  fileList: FileList;
}

interface StyledModalProps extends ModalProps {
  children?: ReactNode;
}

const StyledModal = styled(Modal)<StyledModalProps>`
  .ant-modal-header {
    background-color: #02103d;
    color: white;
    justify-content: space-between;
    align-items: center;
  }
  .ant-modal-title {
    color: white;
  }
  .ant-modal-close-x {
    color: white;
  }
  .loading-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: #f0f0f0;
    padding: 0.4rem;
    span {
      font-size: 14px;
      color: #02103d;
    }
  }
`;

const MinimizedModal = styled.div`
  position: fixed;
  bottom: 20px;
  width: 300px;
  right: 20px;
  background-color: #02103d;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: move;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  user-select: none;
  transition: none;

  &:hover {
    background-color: #162955;
  }

  .progress-bar {
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    display: block;
  }

  .drag-handle {
    cursor: move;
    padding: 4px;
    margin-right: 4px;
  }

  .maximize-button,
  .view-button {
    cursor: pointer;
    margin-left: 8px;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    &:hover {
      opacity: 0.8;
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .divider {
    width: 1px;
    height: 16px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 4px;
  }
`;

const StyledP = styled.p`
  font-size: 14px;
`;

const UploadImageModal: React.FC<UploadImageModalProps> = ({
  isVisible,
  onUpload,
  isLoading,
  isError,
  uploading,
  progress,
  onCancel,
  isMinimized,
  onToggleMinimize,
  onNavigateToEdit,
  isProcessingComplete,
  maskClosable = false,
}) => {
  const [fileList, setFileList] = useState<FileList>([]);
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dragRef = useRef<HTMLDivElement>(null);
  const [currentLoadingMsg, setCurrentLoadingMsg] = useState<string>(
    'Hang tight, we are processing your files...'
  );
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const randomLoadingMessages = [
    'Hang tight, we are processing your files...',
    'Working on your files... almost there!',
  ];

  // Reset fileList when modal becomes hidden
  useEffect(() => {
    if (!isVisible) {
      setFileList([]);
    }
  }, [isVisible]);

  // Handle page reload/navigation
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (uploading || isLoading) {
        // Show the confirmation dialog
        const confirmationDialog = Modal.confirm({
          title: 'Confirm Leave',
          content:
            'You have an upload in progress. Are you sure you want to leave? This will cancel the upload.',
          okText: 'Yes, leave',
          cancelText: 'Stay',
          okButtonProps: {
            style: { backgroundColor: '#ff4d4f', borderColor: '#ff4d4f' },
          },
          onOk: () => {
            if (onCancel) {
              onCancel();
            }
            window.location.reload();
          },
          maskClosable: false,
        });

        // Prevent immediate unload and show our custom dialog
        e.preventDefault();
        // Chrome requires a return value
        e.returnValue = ' ';
      }
    };

    if (uploading || isLoading) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploading, isLoading, onCancel]);

  const handleCancel = () => {
    setFileList([]);
    if (onCancel) {
      onCancel();
    }
  };

  const handleViewClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    if (onNavigateToEdit) {
      onNavigateToEdit();
    }
  };

  return isMinimized ? (
    <MinimizedModal
      ref={dragRef}
      style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
      onMouseDown={(e: React.MouseEvent) => {
        const startX = e.pageX - position.x;
        const startY = e.pageY - position.y;

        const handleMouseMove = (e: MouseEvent) => {
          setPosition({
            x: e.pageX - startX,
            y: e.pageY - startY,
          });
        };

        const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      }}
    >
      <div className="drag-handle">
        <DragOutlined />
      </div>
      <span>
        {isProcessingComplete ? 'Work Order Ready' : 'Uploading Work Orders...'}
      </span>
      {progress > 0 && !isProcessingComplete && (
        <Progress
          percent={progress}
          size="small"
          status="active"
          showInfo={false}
          className="progress-bar"
        />
      )}
      <div className="action-buttons" onClick={(e) => e.stopPropagation()}>
        {isProcessingComplete ? (
          <>
            <div className="view-button" onClick={handleViewClick}>
              <EditOutlined />
              <span>View</span>
            </div>
            <div className="divider" />
          </>
        ) : (
          <div className="maximize-button" onClick={onToggleMinimize}>
            <FullscreenOutlined />
          </div>
        )}
      </div>
    </MinimizedModal>
  ) : (
    <StyledModal
      visible={isVisible}
      maskClosable={maskClosable}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <span>Upload Work Orders - Powered by AI</span>
          <Button
            type="link"
            style={{ color: 'white', marginRight: '30px' }}
            onClick={(e) => {
              e.stopPropagation();
              onToggleMinimize();
            }}
          >
            <MinusOutlined style={{ fontSize: 16 }} />
          </Button>
        </div>
      }
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        isProcessingComplete ? (
          <Button key="view" type="primary" onClick={handleViewClick}>
            View Scanned Work Order
          </Button>
        ) : (
          <Button
            loading={isLoading}
            disabled={!fileList.length}
            key="submit"
            type="primary"
            onClick={() => onUpload(fileList)}
          >
            {isLoading ? 'Processing' : 'Process'}
          </Button>
        ),
      ]}
      width={520}
    >
      <StyledP>
        Upload a single file below, and let Pitstop's AI convert them into
        digital records.
      </StyledP>
      <ul>
        <li>Only English is supported.</li>
        <li>Supported formats are PNG, JPG, PDF (max 5 pages per file).</li>
        <li>Each work order must be a separate file.</li>
        <li>
          Images should be clear, high-res (&gt;250dpi), under 12MB. Avoid
          shadows, skewed pages, or large watermarks.
        </li>
        <li>Handwritten Notes are accepted but not officially supported.</li>
      </ul>
      <StyledP>
        <b>Add Work Orders :</b>
      </StyledP>
      <DocumentUploadList
        isDisabled={fileList.length > 0}
        uploadProps={{
          accept: '.png,.jpg,.jpeg,.pdf',
          onRemove: (file: FileItem) => {
            setFileList((curr) => {
              const index = curr.indexOf(file);
              const newFileList = curr.slice();
              newFileList.splice(index, 1);
              return newFileList;
            });
          },
          beforeUpload: (file: File) => {
            setFileList((curr) => [
              ...curr,
              {
                uid: file.name,
                name: file.name,
                size: file.size,
                type: file.type,
                originFileObj: file,
              } as FileItem,
            ]);
            return false;
          },
          fileList,
          onChange: ({ fileList: newFileList }: UploadChangeParam) => {
            setFileList(newFileList);
          },
          multiple: false,
        }}
      />
      {uploading && (
        <div className="loading-box">
          <Progress percent={progress} />
        </div>
      )}
      {isLoading && (
        <div className="loading-box">
          <span>{currentLoadingMsg}</span>
        </div>
      )}
      {isError && (
        <div className="loading-box">
          <span style={{ color: 'red' }}>
            Something went wrong. Please try again later.
          </span>
        </div>
      )}
    </StyledModal>
  );
};

export default UploadImageModal;